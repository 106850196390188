import { Box, Typography } from '@mui/material';
import React from 'react';

const Footer: React.FC = () => {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        mt: -1,
        textAlign: 'center',
        bgcolor: '#333',
        color: 'white',
        bottom: 0,
        width: '100%',
        position: 'relative',
      }}
    >
      <Typography fontSize={{ xs: '.5rem', sm: '.5rem', md: '.7rem' }}>
        &copy; {new Date().getFullYear()} heyya. All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
