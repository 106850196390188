import { Box, Typography } from '@mui/material';
import React from 'react';
import RotatingText from 'react-rotating-text';

const RotatingTextComponent: React.FC = () => {
  const messages = [
    // "Where digital meets real. No labels, no commitments.",
    // "The convenience of an app, the authenticity of a meeting.",
    // "Connect without hurry, meet without pressure.",
    // "It's just a meetup. Let's see where it goes.",
    // "Genuine connections start without pressure.",
    // "Real meetups for authentic connections."
    // "Create unforgettable moments",

    "Connect with heyyya!",
    "Find your crew!",
    "Discover new connections",
    "Join exciting adventures",
    "Meet like-minded people",
    "Expand your network",
    "Explore the unknown",
  ];

  return (
    <Box
      display="inline-block"
      justifyContent="center"
      alignItems="center"
      sx={{
        height: '80px',
        backgroundColor: '#f8f6fa00',
        backgroundSize: '200% 200%',
        animation: 'gradientBG 10s ease infinite',
        borderRadius: '12px',
        padding: '8px 8px',
        '@keyframes gradientBG': {
          '0%': { backgroundPosition: '0% 50%' },
          '50%': { backgroundPosition: '100% 50%' },
          '100%': { backgroundPosition: '0% 50%' },
        },
        marginTop: { xs: 1, sm: 1, md: 16 },
      }}

    >
      <Typography
        variant="h3"
        sx={{
          backgroundColor: '#f8f6fa',
          backgroundSize: '200% 200%',
          animation: 'gradientBG 10s ease infinite',
          borderRadius: '12px',
          padding: '8px 8px',
          '@keyframes gradientBG': {
            '0%': { backgroundPosition: '0% 50%' },
            '50%': { backgroundPosition: '100% 50%' },
            '100%': { backgroundPosition: '0% 50%' },
          },
          transition: 'opacity 0.5s ease-in-out',
          color: '#333',
          textAlign: 'center',
          overflow: 'hidden',
          maxWidth: '100%',
          textOverflow: 'ellipsis',
          position: 'sticky'
        }}
      >
        <RotatingText typingInterval={70} items={messages} />
      </Typography>

    </Box>
  );
};

export default RotatingTextComponent;
