import MoreVertIcon from '@mui/icons-material/MoreVert'; // Ícone de "More"
import { Box, Button, IconButton, Link, Menu, MenuItem, Toolbar } from '@mui/material';
import React, { useState } from 'react';
import { GradientAppBar } from '../commom/GradientAppBar';
import { TypographyStyled } from '../commom/TypographyStyled';

const Header: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        position: 'fixed',
        top: 20,
        left: 0,
        right: 0,
        margin: 'auto',
        zIndex: 10,
        width: { xs: '100%', md: '700px' },
        padding: { xs: '0 16px', md: 0 },
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
      }}
    >
      <GradientAppBar
        position="static"
        sx={{
          justifyContent: 'center',
          borderRadius: '15px',
          height: 70,
          display: 'flex',
        }}
      >
        <Toolbar sx={{ justifyContent: 'space-between', width: '100%' }}>
          <Box display="flex" alignItems="center" sx={{ alignSelf: 'center', justifyContent: 'center', alignItems: 'center' }}>
            <Box
              component="img"
              src={"/image.png"}
              alt="Logo"
              sx={{ height: '30px', marginRight: '20px', borderRadius: 2 }}
            />
            <TypographyStyled variant="h6" fontWeight="bold" color="primary" noWrap sx={{ alignSelf: 'center', pt: 0.5 }}>
              HEYYYA!
            </TypographyStyled>
          </Box>

          <Box
            display={{ xs: 'none', md: 'flex' }}
            alignItems="center"
            sx={{ gap: '20px' }}
          >
            <Link href="#" color="primary" underline="none" fontSize={"1rem"}>
              More about us
            </Link>
            <Link href="#" color="primary" underline="none" fontSize={"1rem"}>
              Newsroom
            </Link>
            <Link href="#" color="primary" underline="none" fontSize={"1rem"}>
              Revista
            </Link>
          </Box>


          <Box display={{ xs: 'none', md: 'flex' }}>
            <Button variant="contained" sx={{ borderRadius: '12px', pt: 1 }}>
              Join us
            </Button>
          </Box>

          <Box display={{ xs: 'flex', md: 'none' }}>
            <Button variant="contained" sx={{ borderRadius: '12px', pt: 1, height: '10%', alignSelf: 'center' }}>
              <TypographyStyled>Join us</TypographyStyled>
            </Button>
            <IconButton
              color='primary'
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: 48 * 4.5,
                  width: '70%',
                },
              }}
            >
              <MenuItem onClick={handleClose}>
                <Link href="#" color="secondary" underline="none" fontSize={".8rem"}>
                  More about us
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link href="#" color="secondary" underline="none" fontSize={".8rem"}>
                  Newsroom
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link href="#" color="secondary" underline="none" fontSize={".8rem"}>
                  Revista
                </Link>
              </MenuItem>
            </Menu>

          </Box>
        </Toolbar>
      </GradientAppBar>
    </Box>
  );
};

export default Header;
