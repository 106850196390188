import { createReactRouterV6Options, getWebInstrumentations, initializeFaro, ReactIntegration } from '@grafana/faro-react';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import { createRoutesFromChildren, matchRoutes, Routes, useLocation, useNavigationType } from 'react-router-dom';
import App from './App';

initializeFaro({
  url: "http://35.202.48.247:3000/collect/elven/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwicm9sZSI6ImFkbWluIiwiaXNzIjoibGVvIiwiZXhwIjoxNzEyMzExMjMxfQ.4O0yvuihbwD4nzQuKmvKT49oPupwSS_IXtMQu1P5rxI",
  app: {
    name: 'react-app',
    version: '1.0.0',
    environment: 'production'
  },
  sessionTracking: {
    samplingRate: 1,
    persistent: true
  },
  instrumentations: [ 
    ...getWebInstrumentations(),

    new TracingInstrumentation(),

    new ReactIntegration({
      router: createReactRouterV6Options({
        createRoutesFromChildren,
        matchRoutes,
        Routes,
        useLocation,
        useNavigationType,
      }),
    }),
  ],
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
