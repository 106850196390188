import { Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { styled } from '@mui/system';

export const TypographyStyled = styled(Typography)<{ theme?: Theme }>(({ theme }) => ({
  fontSize: theme?.typography.pxToRem(48), 
  [theme?.breakpoints.down('sm')]: {
    fontSize: theme?.typography.pxToRem(12), 
  },
  [theme?.breakpoints.down('xs')]: {
    fontSize: theme?.typography.pxToRem(12),
  },
}));
