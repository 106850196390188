import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import Button from '../../components/commom/Button';
import { endTrackingEvent, startTrackingEvent } from '../../components/commom/Event';

const handleSave = () => {
  console.log("Salvando o formulário...");

  endTrackingEvent({
    stepTitle: "Cadastro de Usuário",
    stepPath: "formulario/cadastro",
    nextPath: "formulario/sucesso",
    eventName: "user-registration",
    description: "Processo de cadastro de usuário",
  });
};

const FormPage: React.FC = () => {
  React.useEffect(() => {
    startTrackingEvent({
      stepTitle: "Cadastro de Usuário",
      stepPath: "formulario/cadastro",
      nextPath: "formulario/sucesso",
      eventName: "user-registration",
      description: "Processo de cadastro de usuário",
    });
  }, []);

  return (
    <Container sx={{ minHeight: 'calc(100vh - 60px)', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box textAlign="center" mt={0}>
        <Typography variant="h2" color="primary">
          Formulário de Cadastro
        </Typography>

        <Button variant="contained" color="primary" size="large" onClick={handleSave}>
          Salvar
        </Button>
      </Box>
    </Container>
  );
};

export default FormPage;
