import { Box, Button, Typography } from "@mui/material";

const AppStore: React.FC = () => {
  return (
    <Button
      href="https://apps.apple.com/app/"
      target="_blank"
      sx={{
        textTransform: 'none',
        padding: 0,
        borderRadius: 3,
        width: { xs: '100px', sm: '150px', md: 'fit-content' },
        '& img': {
          height: { xs: '24px', md: '30px' }, 
        },
        marginRight: { xs: '10px', sm: '20px' }, 
        '&:hover': {
          opacity: 0.8,
          backgroundColor: 'transparent',
        },
        '&:active': {
          opacity: 0.6,
        },
        '&:focus-visible': {
          outline: 'none',
        },
      }}
    >
      <Box
        display="flex"
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor: '#000',
          padding: 1.5,
          minWidth: '200px',
          width: 'fit-content',
          whiteSpace: 'nowrap',
          borderRadius: 3,
        }}
      >
        <Box
          component="img"
          src={"/app-store.png"}
          alt="Logo"
          sx={{ height: '30px', marginRight: '20px', borderRadius: 2 }}
        />
        <Typography color="primary" noWrap >
          App Store
        </Typography>
      </Box>
    </Button>
  );
};

export default AppStore;
