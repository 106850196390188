import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme({
  palette: {
    primary: {
      main: '#f8f6f5',
      light: '#f8f6f5',
    },
    secondary: {
      main: '#333',
      contrastText: '#59159C',
    },
    background: {
      default: '#f8f6f5',
    },
  },
  typography: {
    fontFamily: '"Moderniz Regular", Roboto, sans-serif',
    h1: {
      fontSize: '2rem',
      [`@media (max-width:960px)`]: {
        fontSize: '2.5rem', // Para tablets
      },
      [`@media (max-width:600px)`]: {
        fontSize: '1.8rem', // Para dispositivos móveis
      },
    },
    h2: {
      fontSize: '2.5rem',
      [`@media (max-width:960px)`]: {
        fontSize: '2rem', // Para tablets
      },
      [`@media (max-width:600px)`]: {
        fontSize: '1.5rem', // Para dispositivos móveis
      },
    },
    h3: {
      fontSize: '2rem',
      [`@media (max-width:960px)`]: {
        fontSize: '1.8rem', // Para tablets
      },
      [`@media (max-width:600px)`]: {
        fontSize: '1.2rem', // Para dispositivos móveis
      },
    },
    subtitle1: {
      fontSize: '.8rem',
      [`@media (max-width:960px)`]: {
        fontSize: '.8rem', // Para tablets
      },
      [`@media (max-width:600px)`]: {
        fontSize: '.6rem', // Para dispositivos móveis
      },
      [`@media (max-width:400px)`]: {
        fontSize: '.6rem', // Para dispositivos móveis
      },
    },
    body1: {
      fontSize: '.8rem',
      [`@media (max-width:960px)`]: {
        fontSize: '.8rem', // Para tablets
      },
      [`@media (max-width:600px)`]: {
        fontSize: '.8rem', // Para dispositivos móveis
      },
      [`@media (max-width:400px)`]: {
        fontSize: '.8rem', // Para dispositivos móveis
      },
    },
  },
  
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
