import { Box, Link, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { GradientButton } from '../commom/GradientButton';

const EmailInputComponent: React.FC = () => {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('');

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    // ID da sua planilha Google
    const SPREADSHEET_ID = '1J8RN-8R8n42uvjeubPF0HiHqxTCh3Ym_zoGYUwHcq5I'; // Coloque o ID da sua planilha aqui
    const SHEET_NAME = 'Colleted Emails'; // Nome da aba na planilha
    const API_KEY = 'AIzaSyBLrE9ggFWyTLmgAvRKdV1tsxPFA72N230'; // Coloque sua chave de API aqui

    const url = `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/${SHEET_NAME}:append?valueInputOption=USER_ENTERED&key=${API_KEY}`;
    console.log(url);
    
    const body = {
      values: [[email, new Date().toLocaleString()]], // Enviando email e timestamp
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
    
      const responseBody = await response.json();
      console.log(responseBody);  // Log da resposta para ver mais detalhes
    
      if (response.ok) {
        setStatus('Email enviado com sucesso!');
        setEmail(''); // Limpa o campo de email
      } else {
        setStatus(`Erro ao enviar email: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Erro:', error);
      setStatus('Erro ao enviar email.');
    }
    
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '40%',
        width: { xs: '90%', sm: '70%', md: '50%' },
        backgroundColor: '#f8f6fa',
        padding: '20px',
        borderRadius: 6,
        marginTop: 8,
      }}
    >
      <Typography variant="h3"  gutterBottom>
        Join Us!
      </Typography>
      <TextField
        fullWidth
        label="Enter your email"
        variant="outlined"
        value={email}
        color="secondary"
        onChange={(e) => setEmail(e.target.value)}
        sx={{
          marginBottom: { xs: 1, sm: 1, md: 2 },
          width: { xs: '90%', sm: '90%', md: '90%' },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#888',
            },
            '&:hover fieldset': {
              borderColor: '#333',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#333',
            },
            fontSize: { xs: '12px', sm: '16px', md: '18px' },
            height: { xs: '35px', sm: '40px', md: '45px' },
          },
          '& input': {
            fontFamily: 'Roboto',
            fontSize: { xs: '12px', sm: '16px', md: '18px' },
            color: '#333',
          },
          '& .MuiInputLabel-root': {
            fontSize: { xs: '12px', sm: '14px', md: '16px' },
          }
        }}
      />



      <Box
        sx={{
          width: '90%',
          mb: { xs: 1, sm: 1, md: 2 },
        }}
      >
        <Typography fontFamily={'Roboto'} variant='subtitle1'>
          By continuing, you agree to our{' '}
          <Link href="#" color="secondary.contrastText" fontWeight="bold"  fontFamily={'Roboto'}>
            Terms of Service{' '}
          </Link>
          and acknowledge 
          that you have read our{' '}
          <Link href="#" color="secondary.contrastText" fontWeight="bold"  fontFamily={'Roboto'}>
            Privacy Policy
          </Link>
          .
        </Typography>
      </Box>
      <GradientButton
        type="submit"
        variant="contained"
        color="info"
        onClick={handleSubmit}
        sx={{ alignSelf: 'center', width: '90%', pt: 1 }}
      >
        <Typography>Submit</Typography>
      </GradientButton>
      {status && <p>{status}</p>}
    </Box>
  );
};

export default EmailInputComponent;
