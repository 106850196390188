import { AppBar } from '@mui/material';
import { styled } from '@mui/system';

export const GradientAppBar = styled(AppBar)(({ theme }) => ({
  background: 'linear-gradient(135deg, #f60424, #EF1D2D, #AB001C, #800080, #520052, #450131)',
  backgroundSize: '300% 300%',
  animation: 'gradientBG 15s ease infinite',
  '@keyframes gradientBG': {
    '0%': { backgroundPosition: '0% 50%' },
    '50%': { backgroundPosition: '100% 50%' },
    '100%': { backgroundPosition: '0% 50%' },
  },
}));