import { faro } from '@grafana/faro-web-sdk';

interface TrackEventOptions {
  stepTitle: string;
  stepPath: string;
  nextPath: string;
  eventName: string;
  description: string;
}

let startTime: number | null = null;

export const startTrackingEvent = (options: TrackEventOptions) => {
  startTime = Date.now();

  faro.api.pushEvent(options.eventName, {
    title: options.stepTitle,
    path: options.stepPath,
    description: `${options.description} - Início da etapa: ${options.stepTitle}`,
    order: '1',
    nextPath: options.nextPath,
    timeEvent: startTime.toString(),
    eventType: "start",
  });

  console.log("Evento iniciado:", startTime);
};

export const endTrackingEvent = (options: TrackEventOptions) => {
  if (startTime === null) {
    console.warn("O evento ainda não foi iniciado.");
    return;
  }

  const endTimestamp = Date.now();
  const duration = endTimestamp - startTime;

  faro.api.pushEvent(options.eventName, {
    title: options.stepTitle,
    path: options.stepPath,
    description: `${options.description} - Fim da etapa: ${options.stepTitle}`,
    order: '1',
    nextPath: options.nextPath,
    timeEvent: endTimestamp.toString(),
    eventType: "end",
    duration: duration.toString(),
  });

  console.log("Evento finalizado:", endTimestamp, "Duração:", duration);

  startTime = null;
};
