import { Box, Container, Grid2, Typography } from '@mui/material';
import React from 'react';
import AppStore from '../../components/badgess/app-store';
import GooglePlay from '../../components/badgess/google-play';
import EmailInputComponent from '../../components/commom/EmailInputComponent';
import RotatingText from '../../components/commom/RotatingText';
import { TypographyStyled } from '../../components/commom/TypographyStyled';
import Footer from '../../components/layout/Footer';
import Header from '../../components/layout/Header';

const SubTitle: React.FC = () => {
  return (
    <Box
      display="inline-block"
      sx={{
        backgroundColor: '#f8f6fa',
        backgroundSize: '200% 200%',
        animation: 'gradientBG 10s ease infinite',
        borderRadius: '12px',
        padding: '8px 8px',
        '@keyframes gradientBG': {
          '0%': { backgroundPosition: '0% 50%' },
          '50%': { backgroundPosition: '100% 50%' },
          '100%': { backgroundPosition: '0% 50%' },
        },
        marginTop: 16,
      }}
    >
      <Typography variant="h2" color="secondary.main">
        Break the Ice! 🧊
      </Typography>
    </Box>
  );
};

const Home: React.FC = () => {
  return (
    <>
      <Container
        sx={{
          height: '100vh',
          width: '99vw',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'relative',
          zIndex: 1,
          padding: { xs: '0', md: '20px' },
        }}
      >
        <Box>
          <Header />
          <Box mt={20}>
            <RotatingText />
          </Box>
        </Box>


        <Grid2 display={'flex'} gap={{ md: 4, sm: 1, xs: 1 }} mt={{ md: 48, sm: 24, xs: 24 }} flexDirection={{ md: 'row', sm: 'column', xs: 'column' }}>
          <AppStore />
          <GooglePlay />
        </Grid2>
        <TypographyStyled color="secondary.main" variant='h5' mt={30} sx={{ backgroundColor: '#f5f5f5', padding: 1, borderRadius: 3 }}>
          Where digital meets real.
          <br></br>
          No labels, no commitments.
        </TypographyStyled>
        <EmailInputComponent />
      </Container>

      <Container
        maxWidth={false}
        disableGutters
        sx={{
          width: '100vw',
          padding: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          zIndex: 1,
          overflow: 'hidden',
          marginTop: 86
        }}
      >

        <Box
          sx={{
            display: 'flex',
            width: '100%',
            height: '100%',
            backgroundColor: '#f8f6fa',
            justifyContent: 'center'
          }}
        >
        </Box>
      </Container>
      <Footer />
    </>
  );
};


export default Home;
