import { CssBaseline, GlobalStyles } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './config/routes';
import theme from './styles/theme';

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles styles={{
        body: {
          margin: 0,
          padding: 0,
          fontFamily: 'Roboto, sans-serif',
          backgroundColor: '#f8f6f5',
          overflowX: 'hidden'
        }
      }} />
      <Router>
        <Routes />
      </Router>
    </ThemeProvider>
  );
};

export default App;
