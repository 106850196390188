import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Event from '../pages/Home/Events';
import Home from '../pages/Home/Home';

const RoutesComponent: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/event" element={<Event />} />
      {/* <Route path="/profile" element={<Profile />} />
      <Route path="/chat" element={<Chat />} />
      <Route path="/explore" element={<Explore />} /> */}
    </Routes>
  );
};

export default RoutesComponent;
